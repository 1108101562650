@use '@styles/theme.module' as *;

.title {
  @include typographyH3Bold;

  margin-right: spacing(3);
}

.title_block {
  margin-bottom: spacing(4);
}

.headline_carousel_section {
  gap: spacing(3);
}

.carousel {
  :global(.swiper-wrapper) {
    height: auto;
  }
}

.subhead {
  color: $grayDark;
  margin: spacing(2) 0 0;
  @include typographyBody;
  @include media($from: $lg) {
    @include typographyBodyMedium;
  }
}

.linkText {
  color: $grayDark;
  @include typographyBodyMedium;
}

.hero_image {
  position: relative;
  margin-bottom: spacing(8);

  @include media($from: $xs) {
    height: 245px;
    width: 35%;
  }

  @include media($from: $sm) {
    height: 240px;
    width: 25%;
  }

  @include media($from: $md) {
    height: auto;
    width: auto;
    position: relative;
    top: initial;
    left: initial;
    right: initial;
  }

  @include media($from: $lgbp) {
    margin-bottom: spacing(10);
  }

  img {
    object-fit: cover;
    object-position: center;
  }

  &_skeleton {
    width: 100%;
    height: 100%;
  }
}

.product {
  width: 100%;
  height: 100%;
  padding-top: spacing(7);

  &_grid_item {
    padding-left: spacing(5);
    margin-bottom: spacing(8);
    position: relative;

    @include media($from: $lgbp) {
      margin-bottom: spacing(10);
    }

    &:nth-child(n + 2)::before {
      content: '';
      position: absolute;
      top: spacing(7);
      bottom: spacing(7);
      left: calc(#{spacing(5)} / 2);
      width: 1px;
      background-color: $grayLighter;
      height: 100%;

      @include media($from: $lg) {
        bottom: 0;
        height: auto;
      }
    }

    &:first-child {
      padding-left: 0;
    }

    &:first-child::before {
      width: 0;
    }

    &::after {
      width: 0;
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: $grayLighter;
      height: 1px;
    }
  }
}

.carousel_header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: spacing(6);
  position: relative;

  @include media($from: $md) {
    padding-top: initial;
    align-items: flex-end;
  }

  &_skeleton {
    margin-right: spacing(5);
    @include media($from: $md) {
      margin-right: 0;
    }
  }
}

.carousel_navigation {
  align-self: flex-end;
  position: absolute;
  right: 0;
  bottom: 0;

  // z-index is because Swiper component has default lib value `z-index: 1`
  // and carousel_navigation on drops out from the parent node and hide
  z-index: 2;

  @include media($from: $xs) {
    transform: translateY(60px);
  }

  @include media($from: $sm) {
    transform: initial;
    position: relative;
  }

  @include media($until: $sm) {
    bottom: spacing(4);
  }
}
