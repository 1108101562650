@use '@styles/theme.module' as *;

.wrapper {
  margin-bottom: spacing(6);

  @include media($from: $sm) {
    margin-bottom: spacing(5);
  }

  @include media($from: $md) {
    margin-bottom: spacing(7);
  }
}

.title {
  margin-bottom: spacing(4);
  @include typographyH3Bold;

  @include media($from: $sm) {
    margin-bottom: spacing(3);
  }

  @include media($from: $lg) {
    margin-bottom: spacing(4);
  }
}

.subtitle {
  margin-bottom: spacing(6);

  @include media($from: $sm) {
    margin-bottom: spacing(5);
  }

  @include media($from: $md) {
    margin-bottom: spacing(7);
  }
}
