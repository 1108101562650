@use '@styles/theme.module' as *;

.video {
  border: 0;
  width: 100%;
  height: 237px;

  @include media($from: $sm) {
    height: 354px;
  }
  @include media($from: $lg) {
    height: 382px;
  }
  @include media($from: $xl) {
    height: 417px;
  }
}
