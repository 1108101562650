@use '@styles/theme.module' as *;

.wrapper {
  padding: spacing(6) 0;
  width: 100%;
}

.carousel_wrapper {
  @include media($from: $sm) {
    padding-right: spacing(5);
  }

  @include media($from: $lg) {
    padding-right: spacing(6);
  }

  @include media($from: $xl) {
    padding-right: spacing(5);
  }
}

.divider {
  margin: spacing(6) 0;
}
