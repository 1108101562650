@use '@styles/theme.module' as *;

.banner_wrapper {
  margin-top: spacing(4);

  &:nth-of-type(2) {
    @include media($until: $sm) {
      flex-direction: row-reverse;
    }
  }

  @include media($until: $lg) {
    align-items: center;
  }
}

.description {
  @include media($from: $lg) {
    padding-top: spacing(13);
  }
  @include media($from: $xl) {
    padding-top: spacing(15);
  }
}

.header {
  margin-bottom: spacing(3);
  padding-right: spacing(8);
  @include typographyH5Medium;

  @include media($from: $sm) {
    padding-right: spacing(4);
  }

  @include media($from: $md) {
    padding-right: spacing(13);
  }

  @include media($from: $lg) {
    padding-right: spacing(15);
  }
}

.subheader {
  color: $grayDark;
  @include typographyBody;
}

.link {
  margin: 0 0 spacing(1) spacing(1);
  @include typographyBody;
}

.image_container {
  width: 100%;
  height: 121px;
  position: relative;

  @include media($from: $sm) {
    height: 124px;
  }

  @include media($from: $md) {
    height: 168px;
  }

  @include media($from: $lg) {
    height: 233px;
  }

  @include media($from: $xl) {
    height: 309px;
  }

  img {
    object-fit: contain;
  }
}

.banner_divider {
  margin: spacing(8) 0;
  border-bottom-width: thin;
  border-right-width: thin;

  @include media($from: $sm) {
    margin: 0 spacing(5) 0 spacing(6);
    border-bottom-width: thin;
    border-right-width: thin;
  }
}

.divider {
  margin: spacing(6) spacing(0);
}
