@use '@styles/theme.module.scss' as *;

.wrapper {
  margin-bottom: spacing(8);
  padding: spacing(0) spacing(0) spacing(8) spacing(5);
  width: 100%;

  @include media($from: $sm) {
    margin-bottom: 0;
    height: 100%;
  }

  &.grid_wrapper {
    margin-bottom: 0;
  }
}

.image_wrapper {
  width: 100%;
  height: 400px;

  .link {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
  }
  @include media($from: $sm) {
    height: 100%;
  }
}

.tile {
  position: absolute;
  top: -#{spacing(15)};
  width: 240px;
  height: fit-content !important;
  padding: 0 spacing(5) spacing(4);

  @include media($from: $xl) {
    width: 280px;
  }

  &.grid_tile {
    bottom: -#{spacing(12)};
  }
}

.image {
  position: relative !important;
  object-fit: contain;
}
