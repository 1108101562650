@use '@styles/theme.module' as *;

.header {
  margin-bottom: spacing(5);
  padding-right: spacing(12);

  @include media($from: $sm) {
    padding-right: spacing(4);
  }
}

.subheader {
  width: 100%;
  display: inline-block;
  padding-right: spacing(12);
  color: $grayDark;
}

.link {
  @include typographySmallBodyMedium;
  @include media($from: $lg) {
    @include typographyBodyMedium;
  }
}

.separator_grid {
  display: none;

  @include media($from: $sm) {
    display: block;
  }
}

.image_container {
  width: 100%;
  height: 164px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media($from: $sm) {
    height: 277px;
  }

  @include media($from: $md) {
    height: 270px;
  }

  @include media($from: $lg) {
    height: 360px;
  }

  @include media($from: $xl) {
    height: 454px;
  }

  img {
    object-fit: cover;
  }
}

.skeleton {
  &_image {
    width: 100%;
    height: 100%;
  }
}
