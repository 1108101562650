@use '@styles/theme.module' as *;
@use 'sass:math';

$gap: spacing(6);

@function get-slide-width($columns-count, $gap) {
  $average-gap: calc($gap * ($columns-count - 1) / $columns-count);

  @return calc(100% / $columns-count - $average-gap);
}

.header {
  display: flex;
  margin-bottom: spacing(7);
  align-items: center;
}

.navigation {
  margin-left: auto;
}

.last_slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  line-height: 1.2;
  background: $gray1;
  border-radius: spacing(2);
  text-align: center;

  .link_text {
    border-bottom: none;
    padding: 0 spacing(2);

    @include media($from: $md) {
      padding: 0 spacing(3);
    }
  }

  .with_underline {
    border-bottom: 1px solid $black;
  }
}

.carousel {
  width: 100%;

  :global(.swiper-wrapper) {
    height: auto;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &.v2 {
    .swiper-wrapper {
      display: flex;
      justify-content: space-between;
    }

    .swiper-slide {
      margin-right: 0;
    }
  }

  :global(.swiper-slide) {
    height: unset;
    min-height: 100%;
  }
}

.slide {
  // сurrent styles needed for preventing content blinking in loading process
  height: unset;
  min-height: 100%;
  margin-right: $gap;
  margin-bottom: spacing(10);

  &_fullwidth {
    width: get-slide-width(2, $gap);

    &.v2 {
      margin-right: 0;
    }

    @include media($from: $sm) {
      width: get-slide-width(4, $gap);
    }

    @include media($from: $lg) {
      width: get-slide-width(6, $gap);
    }
  }

  &_halfwidth {
    width: get-slide-width(2, $gap);

    &.v2 {
      margin-right: 0;
    }

    @include media($from: $sm) {
      width: get-slide-width(2, $gap);
    }

    @include media($from: $lg) {
      width: get-slide-width(3, $gap);
    }
  }
}

.separator::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;

  // related to .slide $gap value
  left: calc(-#{$gap / 2});
  width: 1px;
  background-color: $grayLighter;
}
