@use '@styles/theme.module' as *;

// Spacings should be related to margin-bottom in Swiper or Carousel slides with Product Tiles
.spacer {
  &_top {
    padding-top: spacing(8);

    & + & {
      border-top: 1px solid $gray4;
    }
  }

  &_bottom {
    padding-bottom: spacing(8);

    @include media($from: $lgbp) {
      padding-bottom: spacing(10);
    }
  }
}

.green_text {
  > span {
    font-weight: 500;
    color: $primary;
    border-bottom: 1px solid $primary;
    font-size: 1rem;
  }

  @include hover {
    span {
      color: $primaryDarker !important;
      border-color: $primaryDarker !important;
    }
  }
}
