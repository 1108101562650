@use '@styles/theme.module' as *;

.link {
  align-self: center;
  display: inline-block;
  flex: 0 0 auto;
  margin: 0 spacing(3);
  @include typographySmallBodyMedium;

  @include media($from: $lg) {
    @include typographyBodyMedium;
  }
}

.title_wrapper {
  display: flex;
}

.title {
  flex: 0 1 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @include typographyH4Bold;
  @include media($from: $lg) {
    @include typographyH4Medium;
  }
}

.top_pick {
  color: $primaryDark;
}
