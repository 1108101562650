@use '@styles/theme.module' as *;
@use '@styles/_layouts' as *;

.wrapper {
  background: $gray1;
  padding: spacing(8) spacing(5);
  margin: 0;

  @include media($from: $sm) {
    margin: spacing(8) 0;
  }

  @include media($from: $md) {
    padding: spacing(8) spacing(7);
  }

  @include media($from: $lg) {
    padding: spacing(12) spacing(15) spacing(12) spacing(15);
    margin: spacing(10) 0;
  }
}

.title {
  margin-bottom: spacing(2);
  width: 70%;
  @include typographyH3Bold;

  @include media($from: $sm) {
    width: 60%;
  }

  @include media($from: $md) {
    width: 50%;
  }

  @include media($from: $lg) {
    width: 40%;
  }
}

.content {
  p {
    color: $grayDark;
    margin-bottom: spacing(7);
    @include typographyBody;

    @include media($from: $sm) {
      width: 75%;
    }
    @include media($from: $md) {
      width: 60%;
      margin-bottom: spacing(9);
    }
    @include media($from: $xl) {
      width: 55%;
    }
  }

  ul {
    padding-left: 0;
    list-style-type: none;
    color: $black;
    @include typographyBody;

    li {
      margin-bottom: spacing(4);
    }

    li::before {
      content: '✓';
      margin-right: spacing(2);
    }
  }
}

.link {
  border-bottom: 1px solid $black;
  @include typographyBody;

  span {
    color: $black;
    border-bottom: none;
  }
}

.video_container {
  position: relative;
  height: 237px;

  @include media($from: $sm) {
    height: 354px;
    margin-right: spacing(5);
  }
  @include media($from: $md) {
    margin-right: spacing(13);
  }
  @include media($from: $lg) {
    height: 382px;
    margin-right: spacing(14);
  }
  @include media($from: $xl) {
    height: 417px;
    margin-right: spacing(15);
  }

  .play_btn {
    padding: 0;
    position: absolute;
    z-index: 1000;
    top: 50%;
    left: 50%;
    width: 32px;
    height: 32px;
    cursor: pointer;
    color: $white;
  }

  img {
    object-fit: contain;
  }
}

.divider {
  margin: spacing(6) 0;

  @include media($from: $sm) {
    display: none;
  }
}

.terms_link {
  margin-bottom: spacing(7);
}
