@use '@styles/theme.module' as *;
@use 'sass:math';

$gap: spacing(6);

@function get-slide-width($columns-count, $gap) {
  $average-gap: calc($gap * ($columns-count - 1) / $columns-count);

  @return calc(100% / $columns-count - $average-gap);
}

.featured_carousel {
  :global(.swiper-wrapper) {
    height: auto;
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.slide {
  height: auto;
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  justify-content: flex-start;

  &.v2 {
    margin-right: $gap;
    margin-bottom: spacing(10);
  }

  .product {
    width: 100%;
    height: 100%;
    padding-top: spacing(7);

    &_grid_item {
      position: relative;
      width: 50%;
      display: inline-block;
      padding-left: spacing(5);
      margin-bottom: spacing(8);

      @include media($from: $sm) {
        width: 25%;
      }

      @include media($from: $lgbp) {
        margin-bottom: spacing(10);
      }

      &:nth-child(n + 2)::before {
        content: '';
        position: absolute;
        top: spacing(7);
        bottom: spacing(7);
        left: calc(#{spacing(5)} / 2);
        width: 1px;
        background-color: $grayLighter;
        height: 100%;

        @include media($from: $lg) {
          bottom: 0;
          height: auto;
        }
      }

      &:first-child {
        padding-left: 0;
      }

      &.v2 {
        padding-left: 0;

        &:not(.mobile) {
          width: get-slide-width(4, $gap);
        }
      }

      &.v2:nth-child(n + 2)::before {
        content: none;
      }

      &::after {
        width: 0;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: $grayLighter;
        height: 1px;
      }
    }
  }
}

.separator {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: calc(-#{spacing(5) / 2});
    width: 1px;
    background-color: $grayLighter;
  }

  &:last-child {
    &::before {
      width: 0;
    }
  }
}
